import DESKTOP_IMG from "../../assets/images/bg.desktop.png";
import MOBILE_IMG from "../../assets/images/bg.mobile.png";
import NEW_BG from "../../assets/images/bg.new.jpg";
import SocialIcons from "../../components/SocialIcons/SocialIcons";
import { RoutePattern } from "../../routes/RoutePattern";
import { CriOS, iOS } from "../../util";

const Showcase = () => {
  return (
    <div
      id={RoutePattern.HOME}
      className={`${
        CriOS ? "h-[88vh]" : iOS ? "h-[91vh]" : "h-[93vh]"
      } flex flex-col m-auto relative z-20 font-secondary md:h-screen w-full`}
    >
      <div className="w-full h-full relative z-0">
        <img
          className="w-full h-full object-contain object-center cursor-pointer"
          src={window.innerWidth >= 768 ? NEW_BG : NEW_BG}
          alt="bg"
          onClick={() => {
            window.open('https://laylo.com/bossmandlow/m/dlowtour');
          }}
        />
        {/* <BackgroundVideo
          VIDEOGIF={BGMOBILE_GIF}
          VIDEOMP4={BGDESKTOP_MP4}
          VIDEOMP4MOBILE={BGMOBILE_MP4}
        /> */}
      </div>
      <div className="absolute bottom-[5%] left-1/2 transform -translate-x-1/2 z-10">
        <SocialIcons />
      </div>
    </div>
  );
};

export default Showcase;
