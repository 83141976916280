import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import Navigation from "./components/Navigation/Navigation";
import { Footer } from "./components";

export default function App() {
  return (
    <>
      <Router>
        <Navigation />
        <Routes>
          <Route path="*" element={<AppRoutes />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}
