import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  popupActive: false,
  presavePopup: true, // initial
  requiredPopup: true,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setPopupActive: (state, { payload }: PayloadAction<boolean>) => {
      state.popupActive = payload;
    },
    setPresavePopup: (state, { payload }: PayloadAction<boolean>) => {
      state.presavePopup = payload;
    },
    setRequiredPopup: (state, { payload }: PayloadAction<boolean>) => {
      state.requiredPopup = payload;
    },
  },
});

export const { setPopupActive, setPresavePopup, setRequiredPopup } =
  globalSlice.actions;
