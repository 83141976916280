import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { data } from "./data";
import styled from "styled-components";

const SocialLink = styled.a`
  color: white;
  background: black;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
  width: 2.5vw;
  height: 2.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  :not(:last-child) {
    margin-right: 0.8rem;
  }

  @media (max-width: 768px) {
    :not(:last-child) {
      margin-right: 0.25rem;
    }
    width: 25px;
    height: 25px;
    padding: 1rem;
    font-size: 1.25rem;
  }
`;

interface SocialIconProps {
  pandoraIconWidth?: string;
  iconWidth?: string;
}

export default function SocialIcons({
  iconWidth,
  pandoraIconWidth,
}: SocialIconProps) {
  return (
    <ul className="flex flex-row justify-center lg:justify-start text-[3vh]">
      {data.map((item, idx) => (
        <SocialLink key={idx} href={item.link} rel="noreferrer" target="_blank">
          {item.icon ? (
            <FontAwesomeIcon icon={item.icon as any} className="" />
          ) : item.isPandora ? (
            <img
              src={item.img}
              className={pandoraIconWidth}
              style={{
                filter:
                  "invert(8%) sepia(99%) saturate(6379%) hue-rotate(355deg) brightness(90%) contrast(94%)",
              }}
            />
          ) : (
            <img
              src={item.img}
              className={iconWidth}
              style={{
                filter:
                  "invert(8%) sepia(99%) saturate(6379%) hue-rotate(355deg) brightness(90%) contrast(94%)",
              }}
            />
          )}
        </SocialLink>
      ))}
    </ul>
  );
}
